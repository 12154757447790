import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import Scheduler, {
  AppointmentDragging,
  Resource,
} from "devextreme-react/scheduler";
import Query from "devextreme/data/query";
import styled from "styled-components";

import Appointment from "./Appointment.jsx";
import AppointmentTooltip from "./AppointmentTooltip.jsx";
import AppointmentModal from "./AppointmentModal.jsx";
import WaitingListForm from "../Appointments/AddEditWaitingList";

import { toastify } from "../../../helperFunctions/toastify.js";
import { authorizedRequests } from "../../../axios/axios-config.js";
import { useDispatch, useSelector } from "react-redux";
import { resetValue, setData } from "../../../redux/slices/StaffFormsSlices.js";
import { Button, Modal } from "react-bootstrap";
import { getListOfHolidays } from "./../../../apis/holidays";
// const SchedulerWrapper = styled.div`
//   ${props => props.apptWidth && props.apptWidth.map((width, index) => `
//     .dx-scheduler-group-header:nth-child(${index + 1}),
//     .dx-scheduler-date-table-row .dx-scheduler-cell-sizes-horizontal:nth-child(${index + 1}) {
//       width: ${width}px !important;
//     }
//   `)}
  
//   .dx-scheduler-header-panel, .dx-scheduler-date-table {
//     width: 100% !important;
//   }
// `;

const SchedulerWrapper = styled.div`
  ${props => props.apptWidth && props.apptWidth.map((width, index) => `
    .dx-scheduler-group-header:nth-child(${index + 1}),
    .dx-scheduler-date-table-row .dx-scheduler-cell-sizes-horizontal:nth-child(${index + 1}),
    .dx-scrollable-content .dx-scheduler-date-time-indicator:nth-child(${index + 1}) {
      width: ${(props.locationAppts && props.locationAppts[index] && props.locationAppts[index] > 0) ? `${width}px` : `100%`} !important;
    }
  `)}

  .dx-scheduler-header-panel, 
  .dx-scheduler-date-table,
  .dx-scheduler-all-day-panel {
    width: ${props => (props.totalWidth && props.totalWidth >= props.rosteringMaxWidth) ? props.totalWidth : props.rosteringMaxWidth}px !important;
  }

  .dx-item.dx-scheduler-appointment.dx-scheduler-appointment-vertical {
    width: auto !important;
  }

  .dx-item-content.dx-scheduler-appointment-content {
    width: 190px;
  }

  .dx-scheduler-header-panel-empty-cell,
  .dx-scheduler-small .dx-scheduler-time-panel {
    width: 100px !important;
  }
`;

const groups = ["locationId"];

function App(props) {
  const dispatch = useDispatch();
  const { rosteringCardData } = useSelector((state) => state.staffForm);
  const [data, setSchedularData] = useState(props.data);
  const doubleClickTimeout = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalWaitingOpen, setIsModalWaitingOpen] = useState(false);
  const [branches, setBranches] = useState([]);
  // const [loading, setLoading] = useState(true);
  const [oldData, setOldData] = useState();
  const [newData, setNewData] = useState();
  const [isAppointment, setIsAppointment] = useState(true);
  const [waitingObj, setWaitingObj] = useState({});
  const [shouldOpenModal, setShouldOpenModal] = useState(true);

  const handleCellClick = (e) => {
    if (doubleClickTimeout.current) {
      clearTimeout(doubleClickTimeout.current);
      doubleClickTimeout.current = null;

      if (e.cellData) {
        // open waiting list
        if (e.cellData.groups?.locationId === 0) {
          const startTime = `${
            e.cellData.startDate?.getHours() +
            ":" +
            e.cellData.startDate?.getMinutes()
          }`;
          let endTime = "";
          const endHours = (e.cellData.endDate?.getHours() + 1);
          const endMins = e.cellData.endDate?.getMinutes();
          if (endHours >= 21 && endMins >= 0) {
            endTime = "21:00";
          } else {
            endTime = `${endHours}:${endMins}`;
          }

          dispatch(
            setData({
              parent: "waitingListForm",
              value: {
                date: props?.currentDate,
                startTime: startTime,
                endTime: endTime,
              },
            })
          );
          setIsModalWaitingOpen(true);
        } else {
          // open apponitment
          const startTime = `${
            e.cellData.startDate?.getHours() +
            ":" +
            e.cellData.startDate?.getMinutes()
          }`;

          let endTime = "";
          const endHours = (e.cellData.endDate?.getHours() + 1);
          const endMins = e.cellData.endDate?.getMinutes();
          if (endHours >= 21 && endMins >= 0) {
            endTime = "21:00";
          } else {
            endTime = `${endHours}:${endMins}`;
          }
          const branch = branches.find((o) =>
            o.location.find((k) => k._id == e?.cellData?.groups?.locationId)
          );
          const location = branch.location?.find(l => l._id === e?.cellData?.groups?.locationId);

          dispatch(
            setData({
              parent: "appointmentForm",
              value: {
                patient: "",
                branch: branch?._id,
                location: e.cellData.groups?.locationId,
                startTime: startTime,
                endTime: endTime,
                date: e.cellData.startDate,
                package: "",
                staff: location?.staff ? location?.staff : [],
                tag: [],
                otherTags: [],
                status: "Planned",
                remarks: "",
                formStatus: "New",
                editId: null,
              },
            })
          );
          setIsAppointment(true);
          setWaitingObj({});
          setNewData({
            startTime,
            endTime,
            branch,
            location: e.cellData.groups?.locationId,
          });
          if (shouldOpenModal) setIsModalOpen(true);
        }
      }
    } else {
      doubleClickTimeout.current = setTimeout(() => {
        clearTimeout(doubleClickTimeout.current);
        doubleClickTimeout.current = null;
      }, 300); // Adjust the timeout duration to your preference
    }
  };

  const onDrag = async (e) => {
    const { newData, oldData } = e;
    if (newData.locationId == 0 && oldData.locationId != 0) {
      e.cancel = true;
      toastify("Can't drop appointment into waiting list");
      return;
    }
    if (newData.locationId == 0 && oldData.locationId == 0) {
      const startTime = `${
        newData.startDate.getHours() + ":" + newData.startDate.getMinutes()
      }`;
      const endTime = `${
        newData.endDate.getHours() + ":" + newData.endDate.getMinutes()
      }`;
      const { result, error } = await authorizedRequests({
        url: "waitingList/updateWaitingList",
        method: "post",
        data: {
          dataId: newData.id,
          startTime: startTime,
          endTime: endTime,
        },
      });
      if (error) {
        toastify(error.message, "error");
        e.cancel = true;
        return;
      }
      var tempArr = [...rosteringCardData],
        tempArr = tempArr.map((o) => {
          if (o._id == newData.id) {
            return { ...o, startTime: startTime, endTime };
          } else return o;
        });
      dispatch(
        setData({
          parent: "rosteringCardData",
          value: [...tempArr],
        })
      );
      return;
    }
    if (newData.locationId !== 0 && oldData.locationId !== 0) {
      dispatch(resetValue("appointmentForm"));

      setIsAppointment(true);
      const startTime = `${
        newData.startDate.getHours() + ":" + newData.startDate.getMinutes()
      }`;
      const endTime = `${
        newData.endDate.getHours() + ":" + newData.endDate.getMinutes()
      }`;
      // const originalData = rosteringCardData;
      // const originalSchedularData = data;
      // const tempArr = originalData.map((o) => {
      // 	if (o._id == newData.id) {
      // 		return {
      // 			...o,
      // 			location: newData.locationId,
      // 			branch: startTime,
      // 			endTime,
      // 		};
      // 	}
      // 	return o;
      // });
      const branch = branches.find((o) =>
        o.location.find((k) => k._id == newData.locationId)
      );
      setNewData({ ...newData, startTime, endTime, branch: branch });
      setOldData(oldData);
      // const filteredArray = tempArr.filter((o) => {
      // 	return o._id == newData.id;
      // });

      // const tempSchedularData = originalSchedularData.map((o) => {
      // 	if (o.id == newData.id) {
      // 		return {
      // 			...o,
      // 			location: newData.location,
      // 			startTime,
      // 			endTime,
      // 		};
      // 	}
      // 	return o;
      // });

      setIsModalOpen(true);
      // })

      // setOldData(oldData);
      // setIsModalOpen(true);
    }
    if (newData.locationId != 0 && oldData.locationId == 0) {
      dispatch(resetValue("appointmentForm"));
      setIsAppointment(false);
      const startTime = `${
        newData.startDate.getHours() + ":" + newData.startDate.getMinutes()
      }`;
      const endTime = `${
        newData.endDate.getHours() + ":" + newData.endDate.getMinutes()
      }`;
      const allUserData = rosteringCardData.find((o) => o?._id == newData?.id);
      setWaitingObj({
        isFromWaiting: true,
        patient: allUserData.patientName,
        gender: allUserData?.gender,
        waitingId: allUserData?._id,
      });

      setIsModalOpen(true);

      const branch = branches.find((o) =>
        o.location.find((k) => k._id == newData.locationId)
      );
      const location = branch.location?.find(l => l._id === newData?.locationId);

      dispatch(
        setData({
          parent: "appointmentForm",
          value: {
            patient: -1,
            branch: branch._id,
            location: newData.locationId,
            startTime: startTime,
            endTime: endTime,
            date: allUserData.date,
            package: "",
            staff: location?.staff ? location?.staff : [],
            tag: [],
            otherTags: [],
            status: "Planned",
            remarks: allUserData.remarks,
            formStatus: "New",
            editId: null,
          },
        })
      );

      setNewData({ ...newData, startTime, endTime, branch });
      setOldData(oldData);
    }
    // e.cancel = true;
  };

  const listOfHolidays = async () => {
    const { result } = await getListOfHolidays();
    const holiday = result?.data.find((c) => {
      const currentDate = new Date(props.currentDate);
      const startDate = new Date(c?.eventStartDate);
      const endDate = new Date(c?.eventEndDate);

      return currentDate >= startDate && currentDate <= endDate;
    });

    let divElement = document.querySelector(
      ".dx-scheduler-date-table-scrollable"
    );
    if (divElement) {
      if (holiday) {
        divElement.style.backgroundColor = "lightgrey";
      } else {
        divElement.style.backgroundColor = "white";
      }
    }
  };

  useEffect(() => {
    listOfHolidays();
  }, []);

  useEffect(() => {
    (async () => {
      const { result, error } = await authorizedRequests({
        url: "branch/listOfBranch",
        method: "get",
      });
      if (error) {
        toastify(error.message ?? "Failed to get branches", "error");
        return;
      }
      setBranches(result.data);
    })();
  }, []);

  useEffect(() => {
    props.setLoading(true);
    setSchedularData(props.data);
    const id = setTimeout(() => {
      props.setLoading(false);
    }, 1000);
    return () => {
      clearTimeout(id);
    };
  }, [props.data]);

  useEffect(() => {
    const id = setTimeout(() => {
      props.setLoading(false);
    }, 1000);
    return () => {
      clearTimeout(id);
    };
  }, []);

  const getHighestAppointmentCountByLocation = () => {
    const appointmentsCountByLocation = {};
    const highestCountByLocation = {};

    data.forEach(appointment => {
      const location = appointment.locationId;

      // Initialize appointmentsCountByLocation if it's not defined for the location
      if (!appointmentsCountByLocation[location]) {
        appointmentsCountByLocation[location] = {};
      }

      let time = new Date(appointment.startDate);
      while (time < appointment.endDate) {
        const timeKey = time.toISOString();

        // Initialize count for the current location and time slot if it's not defined
        if (!appointmentsCountByLocation[location][timeKey]) {
          appointmentsCountByLocation[location][timeKey] = 0;
        }

        appointmentsCountByLocation[location][timeKey]++;

        // Increment time by 30 minutes
        time.setMinutes(time.getMinutes() + 30);
      }
    });

    // Calculate the highest count for each location
    Object.keys(appointmentsCountByLocation).forEach(location => {
      highestCountByLocation[location] = Math.max(
        ...Object.values(appointmentsCountByLocation[location])
      );
    });

    const highestCounts = props.locations.map((location) => {
      const count = highestCountByLocation[location.id];
      if (!count || count < 1) {
        return 120;
      }

      return count * 200;
    });

    const offsets = highestCounts.map((count, index) => {
      if (index === 0) {
        return 0;
      }

      const prevCount = highestCounts[index - 1] / 200;
      const currentCount = count / 200;
      if ((currentCount < prevCount) && currentCount > 1) {
        return ((prevCount - currentCount) * 100) * -1;
      } else if (currentCount > prevCount) {
        return (currentCount - prevCount) * 100;
      } else {
        return 0;
      }
    });

    return { highestCounts, offsets };
  };

  const { highestCounts, offsets } = getHighestAppointmentCountByLocation(data);
  const totalWidth = highestCounts.reduce((acc, curr) => acc + curr, 0);


  const getTheSameTimeAppt = (appointmentId, locationId, elementIds, firstOffset) => {
    const selectedAppt = data.find(x => x.id === appointmentId);
    if (!selectedAppt) {
      return [];
    }

    const hasTimeConflict = (appointment) => {
      const isConflict = (
        appointment.startDate < selectedAppt.endDate && 
        appointment.endDate > selectedAppt.startDate &&
        appointment.locationId === locationId &&
        elementIds.includes(appointment.id)
      );
      
      return isConflict;
    }

    const conflictedAppts = data.filter(hasTimeConflict);
    const currentIndex = conflictedAppts.map(c => c.id).indexOf(appointmentId);
    const totalOffset = getTotalOffsetLeft(firstOffset, currentIndex);

    return conflictedAppts.filter(appointment => {
      let notConflict = true;
      const elementAppts = document.querySelectorAll(`.dx-item[data-id-${appointment.id}]`);
      if (elementAppts.length > 0) {
        const matrix = getMatrixValue(elementAppts[0]);
        if (totalOffset === matrix.m41) {
          notConflict = false;
        }
      }

      return notConflict;
    });
  }

  const getTotalOffsetLeft = (firstOffset, currentIndex) => {
    let totalOffset = 0;

    for (let m = 0; m <= currentIndex; m++) {
      totalOffset += 195; // 195 min appt
    }

    return firstOffset + totalOffset;
  }

  const getMatrixValue = (element) => {
    const computedStyle = window.getComputedStyle(element);
    const transformValue = computedStyle.getPropertyValue('transform');
    const matrix = new DOMMatrix(transformValue);

    return matrix;
  }

  const retractApptId = (element) => {
    const outerHTML = element.outerHTML.split("data-id-")[1];
    const appointmentId = outerHTML.split("=")[0];

    return appointmentId;
  }

  let isRendered = false;

  useEffect(() => {
    if (offsets.length > 0) {
      setTimeout(() => {
        const schedulerElement = document.querySelector('.dx-scheduler');
        if (schedulerElement && !isRendered) {
          offsets.map((offset, index) => {
            // get the position first appt in this location
            const elementIds = [];
            const listLeft = [];
            const elements = schedulerElement.querySelectorAll(`.dx-item[data-locationid-${props.locations[index].id}]`);
            for (let i = 0; i < elements?.length; i++) {
              const matrix = getMatrixValue(elements[i]);
              listLeft.push(matrix.m41);
            }

            const lowestLeft = Math.min(...listLeft);
            const uniqueLeft = listLeft.filter((value, index, self) => {
              return self.indexOf(value) === index;
            });
            const firstOffset = uniqueLeft[0];

            for (let i = 0; i < elements?.length; i++) {
              const matrix = getMatrixValue(elements[i]);
              if (matrix.m41 !== lowestLeft) {
                const appointmentId = retractApptId(elements[i]);
                elementIds.push(appointmentId);
              }
            }

            // only update position left except first appointment
            for (let i = 0; i < elements?.length; i++) {
              const appointmentId = retractApptId(elements[i]);
              const sameAppts = getTheSameTimeAppt(appointmentId, props.locations[index].id, elementIds, firstOffset);
              const sameApptIds = sameAppts.map(x => x.id);

              const matrix = getMatrixValue(elements[i]);
              if (matrix.m41 > lowestLeft) {
                const currentIndex = sameApptIds.indexOf(appointmentId);
                const totalOffset = getTotalOffsetLeft(firstOffset, currentIndex);
                elements[i].style.transform = `translate(${totalOffset}px, ${matrix.m42}px)`;
              }
            }
    
            return false;
          });

          isRendered = true;
        }
      }, 0);
    }
  }, [offsets, props.locations]);

  const CustomResourceCell = (props) => {
    return <div style={{ color: "#34556F" }}>{props.data.text}</div>;
  };

  const CustomAppointmentTemplate = (props) => {
    return <div style={{ margin: "0" }}>{props.text}</div>;
  };

  // useEffect(() => {
  //   const totalWidth = highestCounts.reduce((acc, curr) => acc + curr, 0);
  //   const schedulerElement = document.querySelector('.dx-scheduler');
  //   const parentElement = document.querySelector('.dx-scheduler-date-table');

  //   if (parentElement) {
  //     console.log("DEBUG", parentElement, totalWidth)
  //   }

  //   if (schedulerElement) {
  //     // set width of parent

  //     let cellIndex = 1;
  //     const horizontalCells = schedulerElement.querySelectorAll('.dx-scheduler-cell-sizes-horizontal');

  //     horizontalCells.forEach((cell) => {
  //       // cell.style.width = `${highestCounts[cellIndex - 1]}px !important`;
  //       if (highestCounts[cellIndex - 1] > 200) {
  //         cell.setAttribute("style", `width: ${highestCounts[cellIndex - 1] + 1}px !important`);
  //       }

  //       if (cellIndex < highestCounts.length + 1) { 
  //         cellIndex++;
  //       }
  //       if (cellIndex === highestCounts.length + 1) {
  //         cellIndex = 1;
  //       }
  //     });
  //   }
  // }, [highestCounts]);

  // const renderDateCell = useCallback((props) => {
  //   console.log("DEBUG", props)
  //   return <div>Test</div>;
  // }, []);

  const groupAppointments = () => {
    const result = props?.locations.map((location) => {
      const locationAppts = data?.filter(d => d?.locationId === location?.id);
      return locationAppts.length;
    });

    return result;
  }

  const locationAppts = groupAppointments();

  const rosteringMaxWidth = window.innerWidth - 250 - 158 - 100 - 50 // sidebar - rostering filter - rostering time - padding;

  return (
    <>
      {!props.loading ? (
        <>
          <SchedulerWrapper 
            locationAppts={locationAppts} 
            apptWidth={highestCounts} 
            totalWidth={totalWidth}
            rosteringMaxWidth={rosteringMaxWidth}
          >
            <Scheduler
              editing={{
                allowAdding: false,
                allowDeleting: false,
                allowResizing: false,
              }}
              // timeZone="America/Los_Angeles"
              dataSource={data}
              adaptivityEnabled
              views={["day"]}
              defaultCurrentView="day"
              defaultCurrentDate={props.currentDate}
              groups={groups}
              height={750}
              width={"100%"}
              onAppointmentUpdating={(e) => {
                onDrag(e);
              }}
              firstDayOfWeek={0}
              startDayHour={9}
              endDayHour={22}
              showAllDayPanel={false}
              crossScrollingEnabled={true}
              cellDuration={30}
              defaultCellWidth={250}
              maxAppointmentsPerCell={10}
              onAppointmentClick={(e) => {
                e.cancel = true;
                const startTime = `${
                  e?.appointmentData?.startDate.getHours() +
                  ":" +
                  e?.appointmentData?.startDate.getMinutes()
                }`;
                const endTime = `${
                  e?.appointmentData?.endDate.getHours() +
                  ":" +
                  e?.appointmentData?.endDate.getMinutes()
                }`;
                const branch = branches.find((o) =>
                  o.location.find((k) => k._id == e?.appointmentData?.locationId)
                );

                if (e?.appointmentData?.locationId != 0) {
                  dispatch(resetValue("appointmentForm"));

                  setIsAppointment(true);
                  setWaitingObj({});
                  setNewData({
                    ...e?.appointmentData,
                    startTime,
                    endTime,
                    branch: branch,
                  });
                  setOldData({ ...e?.appointmentData });
                  if (shouldOpenModal) setIsModalOpen(true);
                } else if (e?.appointmentData?.locationId == 0) {
                  dispatch(resetValue("waitingListForm"));
                  setIsAppointment(false);
                  const allUserData = rosteringCardData.find(
                    (o) => o?._id == e?.appointmentData?.id
                  );
                  setWaitingObj({
                    isFromWaiting: true,
                    patient: allUserData.patientName,
                    gender: allUserData?.gender,
                    waitingId: allUserData?._id,
                  });
                  dispatch(
                    setData({
                      parent: "waitingListForm",
                      value: {
                        dataId: allUserData?._id,
                        patientName: allUserData?.patientName,
                        contactNumber: allUserData?.contactNo,
                        gender: allUserData?.gender,
                        startTime: startTime,
                        endTime: endTime,
                        date: allUserData?.date,
                        remarks: allUserData?.remarks,
                        isRostering: true,
                      },
                    })
                  );
                  setNewData({
                    ...e?.appointmentData,
                    startTime,
                    endTime,
                    branch,
                  });
                  setOldData(e?.appointmentData);
                  if (shouldOpenModal) setIsModalWaitingOpen(true);
                }
              }}
              appointmentComponent={(e) => {
                const gotMultipleAppts = data.filter(
                  (x) => x.id !== e.appointmentData?.id && x
                );
                const selectedSession = props.sessionList?.find(
                  (x) => x.appointment?._id === e?.data?.appointmentData?.id
                );
                const selectedBilling = props.billingList?.find(
                  (x) => x.session?._id === selectedSession?._id
                );

                return (
                  <>
                    <Appointment
                      model={e}
                      statusSession={
                        selectedSession ? selectedSession.status : ""
                      }
                      isPayed={selectedBilling ? selectedBilling.isPayed : false}
                      statusRostering={props.statusRostering}
                      setRefreshAppointments={props.setRefreshAppointments}
                      setRefreshWaiting={props.setRefreshWaiting}
                      setIsModalOpen={(val) => {
                        setShouldOpenModal(val);
                      }}
                    />
                  </>
                );
              }}
              onAppointmentDblClick={(e) => {
                e.cancel = true;
              }}
              onCellClick={handleCellClick}
              resourceCellComponent={CustomResourceCell}
            >
              <AppointmentDragging group={groups} />
              <Resource
                dataSource={data}
                fieldExpr="id"
                useColorAsDefault={true}
              />
              <Resource 
                dataSource={props.locations} 
                fieldExpr="locationId" 
              />
            </Scheduler>
          </SchedulerWrapper>
          <Modal
            centered
            className="p-5 schedularModal waitingModal"
            show={isModalWaitingOpen}
            onHide={() => {
              setIsModalWaitingOpen(!isModalWaitingOpen);
            }}
          >
            <Modal.Body>
              <div>
                <WaitingListForm 
                  setRefreshWaiting={props.setRefreshWaiting}
                  isModalWaitingOpen={isModalWaitingOpen}
                  setIsModalWaitingOpen={setIsModalWaitingOpen}
                />
              </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
          <Modal
            centered
            className="p-5 schedularModal"
            show={isModalOpen}
            onHide={() => {
              setSchedularData(
                data.map((o) => {
                  if (o.id == oldData?.id) {
                    return oldData;
                  } else return o;
                })
              );
              // props.setRefreshAppointments((val) => !val);
              setIsModalOpen(!isModalOpen);
            }}
          >
            <Modal.Body>
              <div>
                <AppointmentModal
                  isModalOpen={isModalOpen}
                  setRefreshAppointments={props.setRefreshAppointments}
                  setRefreshWaiting={props.setRefreshWaiting}
                  updatedData={newData}
                  isAppointment={isAppointment}
                  waitingData={waitingObj}
                  onCancel={() => {
                    setSchedularData(
                      data.map((o) => {
                        if (o.id == oldData?.id) {
                          return oldData;
                        } else return o;
                      })
                    );

                    setIsModalOpen(!isModalOpen);
                  }}
                  setSchedularData={setSchedularData}
                  setIsModalOpen={setIsModalOpen}
                  schedularData={data}
                />
              </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
        </>
      ) : (
        <>
          <div
            className="df-center"
            style={{
              height: "780px",
              width: "50vw",
            }}
          >
            <div className="spinner-border"></div>
          </div>
        </>
      )}
    </>
  );
}

export default App;
