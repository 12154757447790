import { useEffect, useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { authorizedRequests } from "../../../axios/axios-config";
import { toastify } from "../../../helperFunctions/toastify";
import {
  adjustDateFormat,
  convertTimeIntoSeconds,
} from "../../../helperFunctions/utils";
import { resetValue, setData } from "../../../redux/slices/StaffFormsSlices";

import _ from "lodash";

import { SearchIcon } from "../../../assets/images/index";
import Schedular from "./Schedular";
import ComponentWrapepr from "../../SharedComponents/ComponentWrapper";
import { permissionTypes } from "../../../helperFunctions/routes";
import { setTableSearch } from "../../../redux/slices/commonSlice";
import DateInput from "../../SharedComponents/DateInput";
import { getSetting } from "../../../apis/generalSetting";
import { getActiveStaffByDate } from "../../../apis/activeStaff";
import ModalSearch from "./ModalSearch";
import moment from "moment";

export default function Rostering() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { tableSearch } = useSelector((state) => state.commonData);
  const currentDate = tableSearch?.dashboard?.currentDate ? tableSearch?.dashboard?.currentDate : adjustDateFormat(new Date());

  const [listAppointments, setListAppointments] = useState([]);
  const [refreshAppointments, setRefreshAppointments] = useState(false);
  const [refreshWaiting, setRefreshWaiting] = useState(false);
  const {
    // bucketList,
    // currentDate,
    appointmentsForRostering,
    waitingListForRostering,
    rosteringCardData,
  } = useSelector((state) => state.staffForm);

  useEffect(() => {
    if (!location?.state) {
      navigate("/rostering");
    }
  }, [location?.state]);

  const [bucketList, setBucketList] = useState([]);
  const [staff, setStaff] = useState([]);
  const [filteredAppointments, setFilteredAppointments] = useState([]);
  const [filteredWaitingList, setFilteredWaitingList] = useState([]);
  const [filteredStaff, setFilteredStaff] = useState(staff);
  const [showSuggestion, setShowSuggestions] = useState();
  const [search, setSearch] = useState();
  const [loading, setLoading] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [appointmentsForSchedule, setAppointmentForSchedule] = useState([]);
  const [sessionList, setSessionList] = useState([]);
  const [billingList, setBillingList] = useState([]);
  const [statusRostering, setStatusRostering] = useState({});
  const [rosteringDate, setRosteringDate] = useState(currentDate);
  const [holidayDates, setHolidayDates] = useState([]);
  const [descHoliday, setDescHoliday] = useState("");

  const getListOfHolidays = async () => {
    const { result, error } = await authorizedRequests({
      url: "holidays/listOfHolidays",
      method: "get",
    });
    if (error) {
      toastify(error.message ?? "", "error");
      return;
    }

    const disabledDates = [];
    result.data?.map((d) => {
      const holidayStartDate = moment(d.eventStartDate);
      const holidayEndDate = moment(d.eventEndDate);
      // Loop through the dates
      while (holidayStartDate.isSameOrBefore(holidayEndDate)) {
        // Increment the current date by one day
        disabledDates.push({
          date: moment(holidayStartDate).format("DD-MM-YYYY"),
          name: d.description,
        });
        holidayStartDate.add(1, "days");
      }
    });

    setHolidayDates(disabledDates);
  };

  useEffect(() => {
    getListOfHolidays();
  }, []);

  const timeSlots = [
    "00:00",
    "00:30",
    "01:00",
    "01:30",
    "02:00",
    "02:30",
    "03:00",
    "03:30",
    "04:00",
    "04:30",
    "05:00",
    "05:30",
    "06:00",
    "06:30",
    "07:00",
    "07:30",
    "08:00",
    "08:30",
    "09:00",
    "09:30",
    "10:00",
    "10:30",
    "11:00",
    "12:30",
    "12:30",
    "13:00",
    "13:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
    "17:00",
    "17:30",
    "18:00",
    "18:30",
    "19:00",
    "19:30",
    "20:00",
    "20:30",
    "21:00",
    "21:30",
    "22:00",
    "22:30",
    "23:00",
    "23:30",
  ];
  const [locations, setLocations] = useState([]);
  // let tempLocationsArr = [...locations];
  // tempLocationsArr = _.uniqWith(tempLocationsArr, _.isEqual);
  // tempLocationsArr.push({
  //   text: "Waiting List",
  //   id: 0,
  // });
  // if (bucketList?.length === 0) {
  // 	navigate(-1);
  // }
  // useEffect(() => {
  //   if (location?.state?.bucketList?.length > 0) {
  //     dispatch(
  //       setData({
  //         parent: "bucketList",
  //         value: location?.state?.bucketList,
  //       })
  //     );
  //   }
  // }, [location?.state?.bucketList, locations]);

  useEffect(() => {
    getSetting().then((res) => {
      setStatusRostering(res?.statusRostering);
    });
  }, []);

  useEffect(() => {
    authorizedRequests({
      url: "session/listOfSession",
      method: "get",
      params: {
        date: rosteringDate,
      },
    }).then((res) => {
      setSessionList(res?.result?.data);
    });
  }, [rosteringDate]);

  useEffect(() => {
    authorizedRequests({
      url: "payment/listOfPayments",
      method: "get",
      params: {
        date: rosteringDate,
      },
    }).then((res) => {
      setBillingList(res?.result?.data);
    });
  }, [rosteringDate]);

  function AddOrSubractDays(startingDate, number, add) {
    if (add) {
      return new Date(
        startingDate.getFullYear(),
        startingDate.getMonth(),
        startingDate.getDate() + 1
      );
    } else {
      return new Date(
        startingDate.getFullYear(),
        startingDate.getMonth(),
        startingDate.getDate() - 1
      );
    }
  }
  useEffect(() => {
    let tempStaff = [...filteredStaff?.filter((o) => o.isSelected)];
    tempStaff = tempStaff.length > 0 ? tempStaff : filteredStaff;
    // var tempArr = filteredAppointments.filter((o, i) => {
    // 	const containsStaff = o.allStaff.find((staff) => {
    // 		if (tempStaff.find((k) => k.id == staff.staff.id)) {
    // 			return true;
    // 		}
    // 	});
    // 	if (
    // 		filteredAppointments.findIndex(
    // 			(k, idx) => k._id == o._id && idx > i,
    // 		) == -1 &&
    // 		containsStaff
    // 	) {
    // 		return true;
    // 	}
    // });
    var tempArr = filteredAppointments;

    dispatch(
      setData({
        parent: "rosteringCardData",
        value: [...tempArr, ...filteredWaitingList],
      })
    );
    tempArr = tempArr.map((o) => {
      const s_time = o.startTime.split(":");
      const s_h = s_time[0] < 10 ? `${0 + s_time[0]}` : s_time[0];
      const s_m = s_time[1] < 10 ? `${0 + s_time[1]}` : s_time[1];

      const e_time = o.endTime.split(":");
      const e_h = e_time[0] < 10 ? `${0 + e_time[0]}` : e_time[0];
      const e_m = e_time[1] < 10 ? `${0 + e_time[1]}` : e_time[1];

      return {
        id: o._id,
        locationId: o.location,
        startDate: new Date(new Date(o.date).setHours(s_h, s_m, 0)),
        endDate: new Date(new Date(o.date).setHours(e_h, e_m, 0)),
        color: o?.tag[0]?.color ? o?.tag[0]?.color : "#E4DDFC",
        status: o?.status,
      };
    });

    const tempArr2 = filteredWaitingList.map((o) => {
      const s_time = o.startTime.split(":");
      const s_h = s_time[0] < 10 ? `${0 + s_time[0]}` : s_time[0];
      const s_m = s_time[1] < 10 ? `${0 + s_time[1]}` : s_time[1];

      const e_time = o.endTime.split(":");
      const e_h = e_time[0] < 10 ? `${0 + e_time[0]}` : e_time[0];
      const e_m = e_time[1] < 10 ? `${0 + e_time[1]}` : e_time[1];
      return {
        id: o._id,
        gender: o.gender,
        remarks: o.remarks,
        date: o.date,
        startDate: new Date(new Date(o.date).setHours(s_h, s_m, 0)),
        endDate: new Date(new Date(o.date).setHours(e_h, e_m, 0)),
        locationId: 0,
        color: "#E4DDFC",
      };
    });
    const newArr = [...tempArr, ...tempArr2];
    setAppointmentForSchedule(newArr);
  }, [filteredAppointments, filteredWaitingList, filteredStaff]);
  useEffect(() => {
    (async () => {
      const { result, error } = await authorizedRequests({
        url: "waitingList/listOfWaitings",
        method: "get",
        params: {
          date: rosteringDate,
        }
      });
      if (error) {
        toastify(error.message ?? "Failed to get waiting list", "error");
        return;
      }

      const tempArr = result.data.map((o) => {
        return {
          patientName: o.patientName,
          _id: o._id,
          remarks: o.remarks,
          gender: o.gender,
          startTime: o.startTime,
          endTime: o.endTime,
          date: o?.date,
          contactNo: o?.contactNumber,
        };
      });
      dispatch(
        setData({
          parent: "waitingListForRostering",
          value: tempArr,
        })
      );
    })();
  }, [rosteringDate, refreshWaiting]);

  const getAppointmentForRostering = async () => {
    const { result, error } = await authorizedRequests({
      url: "appointment/listOfAppointments",
      method: "get",
      params: {
        date: rosteringDate,
      },
    });
    if (error) {
      toastify(error.message ?? "Failed to get appointments list", "error");
      return;
    }

    setListAppointments(result.data);
  };

  useEffect(() => {
    getAppointmentForRostering();
    getActiveStaff();
  }, [rosteringDate, refreshAppointments]);

  useEffect(() => {
    if (bucketList?.length > 0) {
      let tempArr = listAppointments
      // ?.filter((app) => app.status !== "Not Show")
      // .filter((item) => {
      // 	return bucketList.find((o) => o.id === item.location);
      // })
      .map((item) => {
        const temp = bucketList?.filter((o) => {
          return item?.staff?.find((staff) => staff?._id === o?.staff?.id);
        });
  
        // const temp = item.staff.filter((o) => {
        // 	return bucketList.find((buc) => buc.staff.id === o._id);
        // });
  
        if (temp.length > 0) {
          return {
            ...item,
            staff: temp[0].staff,
            allStaff: temp[0],
            completeStaffList: item.staff,
          };
        } else {
          return {
            ...item,
            allStaff: [],
            completeStaffList: item.staff,
          };
        }
      });
  
      tempArr = _.flattenDeep(tempArr);
      tempArr.sort((a, b) => {
        if (a["startTime"] > b["startTime"]) {
          return 1;
        }
        if (a["startTime"] < b["startTime"]) {
          return -1;
        }
        return 0;
      });
      dispatch(
        setData({
          parent: "appointmentsForRostering",
          value: [...tempArr.filter((o) => o)],
        })
      );
    }
  }, [bucketList, listAppointments])

  const getActiveStaff = async () => {
    const { result, error } = await getActiveStaffByDate({
      date: currentDate,
    });
    if (error) {
      toastify(error.message, "error");
    } else {
      const activeStaff = result?.data?.staff;
      const newBucketList = activeStaff?.map((newStaff) => {
        return {
          id: newStaff.location,
          location: newStaff.locationName,
          staff: {
            department: newStaff.department?.name,
            id: newStaff._id,
            isChecked: true,
            name: newStaff.username,
          },
        };
      });

      setBucketList(newBucketList ? newBucketList : []);
      const newFilteredStaff =
        newBucketList
          ?.filter((o) => o?.staff?.id)
          ?.map((o) => {
            return { ...o.staff, isSelected: false };
          }) ?? [];
      setStaff(newFilteredStaff);
      setFilteredStaff(newFilteredStaff);
      
      let tempLocations = newBucketList.map((item) => {
        return {
          location: item.location,
          text: item.location,
          id: item.id,
        };
      });
      tempLocations = _.uniqWith(tempLocations, _.isEqual)
      setLocations(tempLocations.filter(t => t.location !== ""));
    }
  };

  useEffect(() => {
    setLoading(true);
    // const tempArr = appointmentsForRostering?.filter((o) => {
    // 	return (
    // 		new Date(currentDate).toLocaleDateString() ==
    // 		new Date(o?.date).toLocaleDateString()
    // 	);
    // });
    setFilteredAppointments(appointmentsForRostering);
  }, [appointmentsForRostering]);

  useEffect(() => {
    if (currentDate) {
      const tempArr = waitingListForRostering?.filter(
        (o) =>
          new Date(currentDate).toLocaleDateString() ==
          new Date(o?.date).toLocaleDateString()
      );
      setFilteredWaitingList(tempArr);
    }
  }, [currentDate, waitingListForRostering]);

  // useEffect(() => {
  //   getActiveStaff();
  // }, [currentDate]);

  const convertToCanvas = async (element) => {
    const canvas = await html2canvas(element);

    return canvas.toDataURL("image/png");
    // html2canvas(element).then((canvas) => {
    // 	const imgData = canvas.toDataURL('image/png');
    // 	return imgData;
    // });
  };

  const onSubmitRedux = (value) => {
    dispatch(setTableSearch({
      ...tableSearch,
      dashboard: {
        currentDate: value
      }
    }))
  }

  const onExportPdf = async () => {
    const element = document.getElementById("rostering");
    const scheduleEl = element.querySelector(".dx-scheduler");
    if (scheduleEl) {
      scheduleEl.style.height = "100%";
    }

    html2canvas(element).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("l", "mm", "a4"); // 'l' stands for landscape orientation
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("my-document.pdf");

      if (scheduleEl) {
        scheduleEl.style.height = "750px";
      }
    });
  };

  return (
    <>
      <div className="container px-0 py-0">
        <div className="mainHeading w-100">
          <div
            className="datesButton d-flex bg-theme"
            style={{ borderRadius: "10px", marginRight: "10px" }}
          >
            <Button
              className="  white-600-24 bg-theme border-0"
              style={{ fontWeight: 400 }}
              onClick={() => {
                let currDate = currentDate;

                const previousDate = AddOrSubractDays(
                  new Date(currDate),
                  1,
                  false
                );
                setRosteringDate(previousDate);
                dispatch(
                  setData({
                    parent: "currentDate",
                    value: adjustDateFormat(previousDate),
                  })
                );
                onSubmitRedux(adjustDateFormat(previousDate));
              }}
            >
              {"<"}
            </Button>
            <div className="date align-self-center white px-2 ">
              <div style={{ width: "150px", height: "45px" }}>
                <DateInput
                  background={"#34556f"}
                  calenderClass={"whiteCalender"}
                  inputClassName="themeInput"
                  value={currentDate}
                  shouldDisableDates={true}
                  onChange={(e) => {
                    setRosteringDate(e);
                    dispatch(
                      setData({
                        parent: "currentDate",
                        value: e,
                      })
                    );
                    onSubmitRedux(e);
                  }}
                />
              </div>
            </div>
            <Button
              className="white-600-24 bg-theme border-0"
              style={{ fontWeight: 400 }}
              onClick={() => {
                const nextDate = AddOrSubractDays(
                  new Date(currentDate),
                  1,
                  true
                );
                setRosteringDate(nextDate);
                dispatch(
                  setData({
                    parent: "currentDate",
                    value: adjustDateFormat(nextDate),
                  })
                );
                onSubmitRedux(adjustDateFormat(nextDate));
              }}
            >
              {">"}
            </Button>
          </div>
          <div className="d-flex">
            {descHoliday !== "" && (
              <div
                style={{
                  padding: "10px",
                  backgroundColor: "#f1c40f",
                  color: "white",
                  borderRadius: "10px",
                  marginRight: "20px",
                  height: "40px",
                }}
              >
                {descHoliday}
              </div>
            )}
            <Button
              className="addItem"
              style={{
                height: "55px",
              }}
              onClick={() => onExportPdf()}
            >
              Print
            </Button>
            <Button
              className="addItem ms-2"
              style={{
                height: "55px",
              }}
              onClick={() => setOpenSearch(true)}
            >
              Search Appointment
            </Button>
            <Button
              className="addItem ms-2"
              style={{
                height: "55px",
              }}
              onClick={() => {
                navigate("/rostering", {
                  state: {
                    showBucket: true,
                    isFromRostering: true,
                  },
                });
              }}
            >
              Readjust Active Staff
            </Button>
            <ComponentWrapepr
              moduleName="Appointment"
              permissionType={permissionTypes.CREATE}
            >
              <Button
                className="addItem ms-2"
                style={{
                  height: "55px",
                }}
                onClick={() => {
                  dispatch(resetValue("appointmentForm"));
                  navigate("/add-appointment");
                }}
              >
                + New Appointment
              </Button>
            </ComponentWrapepr>
            <ComponentWrapepr
              moduleName="Appointment"
              permissionType={permissionTypes.CREATE}
            >
              <Button
                className="addItem ms-2"
                style={{
                  height: "55px",
                }}
                onClick={() => {
                  dispatch(resetValue("waitingListForm"));
                  navigate("/add-waiting");
                }}
              >
                + New Waiting List
              </Button>
            </ComponentWrapepr>
          </div>
        </div>
        <div className="roasting-view-table d-flex">
          <div className="name-column">
            <Form.Group
              className="position-relative rostering-search"
              style={{ width: "150px" }}
            >
              <InputGroup
                className="p-0 search-group"
                style={{ borderRadius: 0 }}
                onClick={() => {
                  setShowSuggestions(!showSuggestion);
                }}
              >
                <Form.Control
                  placeholder="Search"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    var tempArr = [...staff];
                    tempArr = tempArr.filter((o) => {
                      return o?.name?.includes(e?.target?.value);
                    });
                    setFilteredStaff(tempArr);
                    if (!e.target.value) {
                      setFilteredStaff(staff);
                    }
                  }}
                />
                <Button style={{ borderRadius: 0 }}>
                  <img src={SearchIcon} />
                </Button>
              </InputGroup>

              {/* {showSuggestion && (
								
							)} */}
            </Form.Group>
            
            <div style={{ maxHeight: "700px", overflowY: "auto", overflowX: "hidden" }}>
              {filteredStaff?.length > 0 &&
                filteredStaff?.map((o, i) => {
                  return (
                    <div
                      className="manual-column pointer"
                      key={i}
                      style={{
                        background: `${o.isSelected ? "#E9E9E9" : "white"}`,
                        minHeight: "64px"
                      }}
                      onClick={() => {
                        let tempArr = [...staff];
                        tempArr = tempArr.map((e) => {
                          if (e.id == o.id) {
                            return {
                              ...e,
                              isSelected: !e.isSelected,
                            };
                          }
                          return e;
                        });
                        setStaff(tempArr);
                        setSearch("");
                        setFilteredStaff(tempArr);
                      }}
                    >
                      <div className="font-500-20" style={{ fontSize: "14px" }}>
                        {o?.name}
                      </div>
                      <div className="grey">{o?.department}</div>
                    </div>
                  );
                })}
            </div>
          </div>

          <div id="rostering">
            <Schedular
              data={appointmentsForSchedule}
              statusRostering={statusRostering}
              sessionList={sessionList}
              billingList={billingList}
              setRefreshAppointments={setRefreshAppointments}
              setRefreshWaiting={setRefreshWaiting}
              locations={[
                ...locations,
                {
                  text: "Waiting List",
                  id: 0,
                }
              ]}
              loading={loading}
              setLoading={setLoading}
              currentDate={new Date(currentDate)}
              allData={[
                ...rosteringCardData?.map((o, i) => {
                  const col =
                    o?.tag && o?.tag?.length ? o?.tag[0]?.color : "#E4DDFC";
                  return {
                    ...o,
                    color: col,
                  };
                }),
              ]}
            />
          </div>
        </div>
      </div>

      <ModalSearch open={openSearch} setOpen={setOpenSearch} />
    </>
  );
}
